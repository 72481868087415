.Register-container{
    width: 25vw;
    height: fit-content;
    padding: 40px 80px;
    margin: 5vh auto;
    animation: slideDown 0.5s ease-in-out;
    
}
.label{
    margin-bottom: 0.5em;
    font-weight: 500;
}


@keyframes slideDown {
    from {
      transform: translateY(-30%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }


  @media screen and (max-width : 400px)
  
  {
    *{
      overflow-x: hidden;
    }
    .form-container{
      width: 60vw;
    height: fit-content;
    padding: 10px;
    margin: 20vh auto;
    animation: slideDown 0.5s ease-in-out;
    }
  }