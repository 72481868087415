.cart-container{
    margin: 3vh auto;
    width: 50%;
    padding: 20px;
}
.card-item{
    margin: 2em auto;
    display: flex;
    border: 1px solid rgb(168, 168, 168);
    border-radius: 6px;
    justify-items: center;
    justify-content: space-between;
    padding: 2em;
    width: 400px;
    height: 10vh;
    align-items: center;
}
.item-left{
    display: flex;
}
.img-box img{

    height: 6em;
    width: 6em;
}

.item-content{
    width: 7em;
    display: flex;
    text-align: start;
    align-items: center;
}
.cart-container .total{
    text-align: end;
    margin-right: 10em;
}