/* styles.css */

/* Global styles */
body {
    font-family: 'Arial', sans-serif;
  }
  
  /* Product List */
  .container {
    margin-top: 20px;
  }
  
  .card {
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .card img {
    height: 200px;
    object-fit: cover;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  /* Cart */
  .cart {
    margin-top: 40px;
  }
  
  .cart h2 {
    margin-bottom: 20px;
  }
  
  .cart .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cart .btn-danger {
    margin-left: 10px;
  }
  
  .cart .btn-primary {
    margin-top: 10px;
  }
  
  /* Product Modal */
  .modal.show {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    width: 100%;
    max-width: 600px;
  }
  
  .modal img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Payment Page */
  .payment-page {
    margin-top: 20px;
  }
  
  .qr-code {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .qr-code canvas {
    width: 200px;
    height: 200px;
  }
  
  .payment-page form {
    margin-top: 20px;
  }
  
  .payment-page .form-group {
    margin-bottom: 15px;
  }
  
  .payment-page .btn-primary {
    margin-top: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 767px) {
    .card img {
      height: 150px;
    }
  
    .modal-content {
      padding: 10px;
    }
  
    .qr-code canvas {
      width: 150px;
      height: 150px;
    }
  }
  
  /* ProductList.css */

.product-list-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-list-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1.2em;
  font-family: 'Arial', sans-serif;
  min-width: 600px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-list-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.product-list-table th,
.product-list-table td {
  padding: 12px 15px;
}

.product-list-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.product-list-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.product-list-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.product-list-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.accept-button,
.reject-button {
  padding: 8px 12px;
  margin-right: 5px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accept-button {
  background-color: #28a745;
}

.accept-button:hover {
  background-color: #218838;
}

.reject-button {
  background-color: #dc3545;
}

.reject-button:hover {
  background-color: #c82333;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  position: relative;
  text-align: center;
  border-radius: 8px;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover,
.close-button:focus {
  color: #000;
  text-decoration: none;
}

.modal img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}